.topBar {
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &Passcode {
    @media screen and (max-width:600px) {
      height: 8rem;
      box-shadow: none;
    }
  }

  .logo {
    height: 100%;

    &Passcode {
      @media screen and (max-width:600px) {
        height: 50%;
      }
    }
  }
}