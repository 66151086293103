.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  @media screen and (max-width:600px) {
    background-color: #2d4ded;
  }

  .content {
    width: 100%;
    height: calc(100% - 4rem);
    display: flex;
    justify-content: center;
    align-items: center;

    &Passcode {
      @media screen and (max-width:600px) {
        height: calc(100% - 8rem);
      }
    }
  }
}